import React, { useCallback } from 'react';
import { observer } from 'mobx-react-lite';

import {
  EAccountTypeNames,
  hasCopierFunctionality,
  isChallengeAccountType,
} from '@trader/constants';
import { TTradingAccountEntity, useMst } from '@trader/store';
import { EChallenge } from '@trader/types';
import { EConditionType, useBusinessLogic } from '@trader/hooks';

import { Account } from './components';
import * as Styled from './styled';

interface ITradingAccounts {
  onClose?: () => void;
}

export const TradingAccounts: React.FC<ITradingAccounts> = observer(
  ({ onClose }) => {
    const store = useMst();
    const { isSecondCaseCondition } = useBusinessLogic(
      EConditionType.ScoreLevel
    );

    const [openAccId, setOpenAccId] = React.useState<number | null>(null);

    const toggleOpen = useCallback((accountId: number) => {
      setOpenAccId(prevId => (accountId === prevId ? null : accountId));
    }, []);

    const accounts =
      store.entities.tradingAccounts.getAll<TTradingAccountEntity>();

    // if ScoreLevel === LOW we render only demo accounts
    // if app has challenges we render not disabled accounts
    // render all accounts
    const tradingAccounts = isSecondCaseCondition
      ? accounts.filter(acc => acc.accountType === EAccountTypeNames.Demo)
      : isChallengeAccountType
      ? accounts.filter(acc => acc.challenge?.status !== 'DISABLED')
      : accounts;

    // if we don't have copier logic we render tradingAccounts
    // if we have copier we render all the accounts that aren't slave. Slaves we render in drop-down in master only
    const accountsToRender = !hasCopierFunctionality
      ? tradingAccounts
      : tradingAccounts.filter(
          acc => acc.challenge?.hierarchyType !== EChallenge.Slave
        );

    return (
      <Styled.Root>
        {accountsToRender.map(tradingAccount => (
          <Account
            key={tradingAccount.tradingId}
            tradingAccount={tradingAccount}
            onClose={onClose}
            toggleOpen={toggleOpen}
            openAccId={openAccId}
          />
        ))}
      </Styled.Root>
    );
  }
);
